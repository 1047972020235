import React from "react"
import Seo from 'components/seo'
import Layout from "layouts/layout";
import * as styles from './index.module.less'

import Button from 'components/Base/Button'

var icons = [
  'check_circle.svg',
  'editor.svg',
  'email.svg',
  'icon-advantage-active.svg',
  'icon-advantage.svg',
  'icon-arrow-right.svg',
  'icon-avatar-default.svg',
  'icon-close-active.svg',
  'icon-close-white.svg',
  'icon-close.svg',
  'icon-conversion-active.svg',
  'icon-conversion.svg',
  'icon-database-active.svg',
  'icon-database.svg',
  'icon-doc-active.svg',
  'icon-doc.svg',
  'icon-download.svg',
  'icon-engine-active.svg',
  'icon-engine.svg',
  'icon-goods-active.png',
  'icon-goods.png',
  'icon-identify-active.svg',
  'icon-identify.svg',
  'icon-lifecycle-active.svg',
  'icon-lifecycle.svg',
  'icon-logout-round-white.svg',
  'icon-logout-round.svg',
  'icon-logout.svg',
  'icon-loyal.svg',
  'icon-new.svg',
  'icon-newUser-active.svg',
  'icon-newUser.svg',
  'icon-optimize-active.svg',
  'icon-optimize.svg',
  'icon-output-active.svg',
  'icon-output.svg',
  'icon-person-white.svg',
  'icon-person.svg',
  'icon-plus.svg',
  'icon-policy-active.svg',
  'icon-policy.svg',
  'icon-portrayal-active.svg',
  'icon-portrayal.svg',
  'icon-promote-active.svg',
  'icon-promote.svg',
  'icon-report-active.png',
  'icon-report-active.svg',
  'icon-report.png',
  'icon-report.svg',
  'icon-round-shadow.svg',
  'icon-service-active.svg',
  'icon-service.svg',
  'icon-sign.svg',
  'icon-start.svg',
  'icon-subtract.svg',
  'icon-success.svg',
  'icon-trace-active.svg',
  'icon-trace.svg',
  'icon-universe-active.svg',
  'icon-universe.svg',
  'icon-userLoyalty-active.svg',
  'icon-userLoyalty.svg',
  'icon-value-active.svg',
  'icon-value.svg',
  'icon-video.svg',
  'icon-wait.svg',
  'inspect-active.svg',
  'inspect.svg',
  'logo-analysis-cloud.svg',
  'logo-data-base.svg',
  'logo-marketing-cloud.svg',
  'name.svg',
  'photo.svg',
  'right_arrow.svg',
  'video-play.svg',
  'icon-right.svg',
  'user-operation.svg',
  'user-operation-active.svg',
  'user-insight.svg',
  'user-insight-active.svg',
  'content-recommend.svg',
  'content-recommend-active.svg',
  'optimize.svg',
  'optimize-active.svg'
]

const UI: React.FC = props => {

  return (
    <Layout {...props}>
      <Seo 
        title="官网组件列表"
      />
      <div className={styles.uiDemo}>
        <h2 className='title'>按钮</h2>
        <div className='row'>
          <Button btnType="primary">Primary</Button>
          <div style={{display: 'inline-block', width: 10}}></div>
          <Button btnType="secondary">Secondary</Button>
          <div style={{display: 'inline-block', width: 10}}></div>
          <Button btnType="primary" size='small'>Primary Small</Button>
        </div>
        <div className='row' style={{background: 'rgb(245, 251, 255)'}}>
          <Button>Default</Button>
          <div style={{display: 'inline-block', width: 10}}></div>
          <Button btnType="ghost" ghost>Ghost</Button>
        </div>
        <h2 className='title'>Icons</h2>
        <div className='row icon-list' style={{background: 'rgb(245, 251, 255)'}}>
          <p>文件夹：assets/svgs/*.svg</p>
          {icons?.map(item => <div key={item} className="icon-item">
            <img src={require(`assets/svgs/${item}`).default} />
            <p style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }} title={item}>{item}</p>
          </div>)}
        </div>
      </div>
    </Layout>
  );
}

export default UI;